:root {
  --color-secondary: #800080;
  --color-secondary-dark: #2E1114;
  --color-gray: #808080;
  --color-gray-dark: #596a74;
  --color-gray-dark-2: #333;
  --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
  --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);
  --line: 1px solid var(--color-gray); }

/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: border-box;
  background-color: #FAF9F6; }

::selection {
  background-color: #54aa01;
  color: #FFF; }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(4rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: #000; }

.heading-primary {
  display: flex;
  flex-direction: column;
  color: #FFF;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden; }
  .heading-primary__main {
    font-size: 4.5rem;
    font-weight: 400;
    letter-spacing: .7rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out;
    /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */ }
    @media only screen and (max-width: 37.5em) {
      .heading-primary__main {
        letter-spacing: 0.6rem;
        font-size: 1.9rem; } }
    @media only screen and (max-width: 22.1em) {
      .heading-primary__main {
        letter-spacing: normal;
        font-size: 1.6rem; } }
  .heading-primary__sub {
    font-size: 3rem;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: .7rem;
    animation: moveInRight 2s ease-out;
    animation-delay: 1s; }
    @media only screen and (max-width: 37.5em) {
      .heading-primary__sub {
        letter-spacing: .5rem;
        font-size: 1.3rem; } }
    @media only screen and (max-width: 22.1em) {
      .heading-primary__sub {
        letter-spacing: normal;
        font-size: 1rem; } }

.heading-secondary {
  font-size: 4rem;
  font-weight: 700;
  background-image: linear-gradient(to right, #54aa01, #54aa01);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .heading-secondary {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-secondary {
      line-height: normal; } }
  @media only screen and (max-width: 16em) {
    .heading-secondary {
      font-size: 1.5rem; } }

.heading-secondary-white {
  font-size: 4rem;
  font-weight: 900;
  color: #FFF;
  letter-spacing: .2rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .heading-secondary-white {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-secondary-white {
      font-size: 2.7rem; } }
  @media only screen and (max-width: 16em) {
    .heading-secondary-white {
      font-size: 2.2rem; } }

.heading-secondary-black {
  font-size: 4rem;
  font-weight: 900;
  color: #000;
  letter-spacing: .2rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .heading-secondary-black {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-secondary-black {
      font-size: 2.7rem; } }
  @media only screen and (max-width: 16em) {
    .heading-secondary-black {
      font-size: 2.2rem; } }

.heading-secondary-medium {
  font-size: 3.3rem;
  font-weight: 700;
  background-image: linear-gradient(to right, #54aa01, #54aa01);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .heading-secondary-medium {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-secondary-medium {
      line-height: normal; } }
  @media only screen and (max-width: 16em) {
    .heading-secondary-medium {
      font-size: 2.2rem; } }

.heading-tertiary {
  font-size: 3.3rem;
  font-weight: 600;
  color: #000;
  letter-spacing: .2rem; }
  @media only screen and (max-width: 56.25em) {
    .heading-tertiary {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-tertiary {
      font-size: 2.5rem; } }
  @media only screen and (max-width: 22.1em) {
    .heading-tertiary {
      font-size: 2rem; } }

.heading-tertiary-alt {
  font-size: 2.5rem;
  font-weight: 700;
  color: #000;
  letter-spacing: .2rem; }
  @media only screen and (max-width: 56.25em) {
    .heading-tertiary-alt {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-tertiary-alt {
      font-size: 2.5rem; } }
  @media only screen and (max-width: 22.1em) {
    .heading-tertiary-alt {
      font-size: 2rem; } }

.heading-tertiary-white {
  font-size: 2.5rem;
  font-weight: 700;
  color: #FFF;
  letter-spacing: .2rem; }
  @media only screen and (max-width: 56.25em) {
    .heading-tertiary-white {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-tertiary-white {
      font-size: 2.5rem; } }
  @media only screen and (max-width: 22.1em) {
    .heading-tertiary-white {
      font-size: 2rem; } }

.heading-tertiary-normal {
  font-size: 2rem;
  font-weight: 600; }
  @media only screen and (max-width: 22.1em) {
    .heading-tertiary-normal {
      font-size: 1.5rem; } }

.heading-tertiary-primary {
  color: #54aa01;
  font-size: 2.2rem;
  font-weight: 600; }
  @media only screen and (max-width: 22.1em) {
    .heading-tertiary-primary {
      font-size: 1.5rem; } }

.heading-tertiary-normal-white {
  font-size: 2rem;
  font-weight: 600;
  color: #FFF; }
  @media only screen and (max-width: 22.1em) {
    .heading-tertiary-normal-white {
      font-size: 1.5rem; } }

.paragraph {
  font-size: 1.8rem;
  font-weight: 500;
  color: #000; }
  @media only screen and (max-width: 22.1em) {
    .paragraph {
      font-size: 1.6rem; } }

.paragraph-white {
  font-size: 1.8rem;
  font-weight: 500;
  color: #FFF; }
  @media only screen and (max-width: 22.1em) {
    .paragraph-white {
      font-size: 1.6rem; } }

.paragraph-primary {
  font-size: 1.8rem;
  font-weight: 600;
  color: #54aa01; }
  @media only screen and (max-width: 22.1em) {
    .paragraph-primary {
      font-size: 1.6rem; } }

.u-center-text {
  text-align: center !important; }

.u-margin-top-small {
  margin-top: 1.5rem !important; }

.u-margin-top-medium {
  margin-top: 4rem !important; }

.u-margin-top-large {
  margin-top: 8rem !important; }

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }

.u-margin-bottom-large {
  margin-bottom: 8rem !important; }

.u-margin-left-small {
  padding-left: 1.5rem; }

.u-margin-left-medium {
  padding-left: 4rem; }

.u-margin-left-large {
  padding-left: 8rem; }

.top-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  z-index: 9800;
  background-color: rgba(46, 17, 20, 0.8);
  color: #54aa01;
  font-size: 1.4rem; }
  .top-header__nav-list {
    display: flex;
    justify-content: space-evenly;
    align-items: center; }
  .top-header__nav-item:not(:last-child) {
    margin-right: 2rem; }
  .top-header__btn {
    background-color: #FFF;
    border-radius: .8rem;
    padding: .3rem 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center; }
  .top-header__btn a {
    text-decoration: none;
    color: #54aa01; }
  .top-header__btn:hover {
    cursor: pointer; }
  .top-header__btn:hover > a {
    text-decoration: underline;
    color: #800080; }
  .top-header__user-avatar {
    position: relative; }
    @media only screen and (max-width: 56.25em) {
      .top-header__user-avatar {
        position: static; } }
    .top-header__user-avatar-icon {
      color: #54aa01;
      font-size: 2.5rem;
      cursor: pointer; }
    .top-header__user-avatar-icon:hover {
      color: #800080; }
  .top-header__user-avatar-submenu {
    position: absolute;
    top: 4rem;
    right: 0;
    background-color: #FFF;
    width: 30rem;
    padding: 1.5rem;
    padding-left: 2rem;
    border-radius: .6rem;
    box-shadow: 3px 2px 2px 4px rgba(0, 0, 0, 0.1);
    visibility: hidden; }
    @media only screen and (max-width: 56.25em) {
      .top-header__user-avatar-submenu {
        top: 5rem;
        width: 40vw; } }
    @media only screen and (max-width: 37.5em) {
      .top-header__user-avatar-submenu {
        width: 98vw;
        margin-left: 1vw;
        margin-right: 1vw; } }
    .top-header__user-avatar-submenu ul {
      list-style-type: none;
      font-size: 2rem;
      color: #000; }
      .top-header__user-avatar-submenu ul li {
        margin-bottom: .6rem; }
        .top-header__user-avatar-submenu ul li:first-child {
          border-bottom: 2px solid #000;
          margin-bottom: .7rem;
          padding-top: .5rem; }
        .top-header__user-avatar-submenu ul li:last-child {
          border-top: 2px solid #000;
          margin-top: .7rem; }
        .top-header__user-avatar-submenu ul li a:link, .top-header__user-avatar-submenu ul li a:visited, .top-header__user-avatar-submenu ul li a:active {
          text-decoration: none;
          font-size: 1.9rem;
          color: #000; }
        .top-header__user-avatar-submenu ul li a:hover {
          color: #800080; }

.show-user-submenu {
  visibility: visible; }

.hide-user-submenu {
  visibility: hidden; }

.dis-tab-port {
  display: none; }
  @media only screen and (max-width: 56.25em) {
    .dis-tab-port {
      display: block; } }

.dis-none-phone {
  display: static; }
  @media only screen and (max-width: 37.5em) {
    .dis-none-phone {
      display: none; } }

.header {
  position: fixed;
  top: 5rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FAF9F6;
  box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.2);
  z-index: 9500; }
  .header__nav-items {
    font-size: 1.8rem; }
    .header__nav-items a {
      text-decoration: none;
      margin-right: 2rem;
      padding-bottom: 3.3rem; }
      .header__nav-items a:link {
        color: #54aa01; }
      .header__nav-items a:hover {
        color: #800080;
        border-bottom: 4px solid #800080; }
    @media only screen and (max-width: 56.25em) {
      .header__nav-items {
        display: none; } }
  .header__link {
    color: #54aa01;
    margin-right: 2rem; }
  .header__link-active {
    color: #800080;
    margin-right: 2rem;
    border-bottom: 4px solid #800080; }
  .header__nav {
    display: none; }
    @media only screen and (max-width: 56.25em) {
      .header__nav {
        display: block; } }
  .header__search-and-sidebarButton {
    display: flex; }

.logo {
  height: 9rem;
  margin-left: 2rem;
  animation: moveInTop 1s ease-out; }
  @media only screen and (max-width: 37.5em) {
    .logo {
      height: 7rem; } }

.search {
  flex: 0 0 25%;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 75em) {
    .search {
      display: none; } }
  .search__input {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    border: 0.5rem solid #54aa01;
    padding: .7rem 2rem;
    border-radius: 10rem;
    box-shadow: var(--shadow-light);
    width: 80%;
    transition: all .2s;
    margin-right: -3.25rem; }
    @media only screen and (max-width: 37.5em) {
      .search__input {
        border: 0.3rem solid #54aa01; } }
    .search__input:focus {
      outline: none;
      width: 90%;
      background-color: #FFF; }
    .search__input::-webkit-input-placeholder {
      font-weight: 100;
      color: #808080; }
  .search__input:focus + .search__button {
    color: #800080 !important; }
  .search__button {
    border: none;
    background-color: #FFF;
    color: #54aa01;
    cursor: pointer; }
    .search__button:focus {
      outline: none; }
    .search__button:active {
      transform: translateY(2px); }
  .search__icon {
    height: 2rem;
    width: 2rem;
    background-color: inherit; }

.search-icon {
  display: none;
  flex: 0 0 5%; }
  @media only screen and (max-width: 75em) {
    .search-icon {
      display: block;
      order: 1; } }
  @media only screen and (max-width: 56.25em) {
    .search-icon {
      display: none; } }
  .search-icon i {
    font-size: 3rem;
    color: #000;
    cursor: pointer; }
  .search-icon i:hover {
    color: #800080;
    box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.1); }

.search-icon-tab-port {
  display: none;
  flex: 0 0 5%;
  margin-right: 4rem; }
  @media only screen and (max-width: 56.25em) {
    .search-icon-tab-port {
      display: block; } }
  .search-icon-tab-port i {
    font-size: 3rem;
    color: #000;
    cursor: pointer; }
  .search-icon-tab-port i:hover {
    color: #800080;
    box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.1); }

.sidebar-button {
  color: #54aa01;
  font-size: 3rem;
  border-radius: .5rem;
  border-color: #54aa01;
  padding: .3rem .6rem;
  margin-right: 2rem;
  background-color: #FFF;
  box-shadow: 0 0 2rem 1rem rgba(0, 0, 0, 0.06);
  transition: .1s ease;
  animation: moveInTop 1s ease-out; }
  .sidebar-button:hover {
    cursor: pointer;
    color: #800080; }
  .sidebar-button:active {
    transform: scale(0.8); }
  .sidebar-button__icon {
    background-color: white; }

.toast-style {
  font-size: 2rem !important;
  z-index: 12000 !important; }

.sidebar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 4rem;
  padding-top: 6rem;
  position: fixed;
  transition: all 0.7s ease;
  opacity: 0;
  width: 10%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #2E1114;
  visibility: hidden;
  font-size: 1.6rem;
  z-index: 11000; }
  .sidebar__list {
    list-style-type: none;
    text-transform: uppercase;
    opacity: 0; }
  .sidebar__link {
    color: #FFF; }
    .sidebar__link:link {
      text-decoration: none; }
    .sidebar__link:hover, .sidebar__link:active {
      color: #800080; }
  .sidebar__item {
    margin-bottom: 2.4rem; }

.show-sidebar {
  visibility: visible;
  opacity: 1;
  width: 25%; }
  @media only screen and (max-width: 56.25em) {
    .show-sidebar {
      width: 45%;
      padding-left: 7rem;
      padding-top: 10rem;
      font-size: 2rem; } }
  @media only screen and (max-width: 37.5em) {
    .show-sidebar {
      width: 70%;
      padding-left: 4rem;
      font-size: 1.8rem; } }
  .show-sidebar .sidebar__list {
    opacity: 1; }

.sidebar-submenu__link {
  opacity: 0;
  color: #FFF; }
  .sidebar-submenu__link:link {
    text-decoration: none; }
  .sidebar-submenu__link:hover, .sidebar-submenu__link:active {
    color: #800080; }

.sidebar__submenu-1 {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  opacity: 0;
  flex-direction: column;
  padding-left: 2rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  color: #2E1114 !important;
  transition: all .7s ease-in; }

.show-submenu-1 {
  position: static;
  visibility: visible;
  opacity: 1; }
  .show-submenu-1 a {
    opacity: 1; }

.sidebar__submenu-2 {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  opacity: 0;
  flex-direction: column;
  padding-left: 2rem;
  padding-bottom: 1rem;
  font-size: 1.3rem;
  color: #2E1114 !important;
  transition: all .7s; }

.show-submenu-2 {
  position: static;
  visibility: visible;
  opacity: 1; }
  .show-submenu-2 a {
    opacity: 1; }

.fa-chevron-right {
  margin-left: 1rem; }

.fa-chevron-down {
  margin-left: 1rem; }

.btn {
  margin-bottom: .5rem; }
  .btn:link, .btn:visited {
    font-size: 1.6rem;
    font-weight: 600;
    color: #800080;
    display: inline-block;
    text-decoration: none;
    border-bottom: 2px solid #800080;
    padding: 3px;
    transition: all .2s; }
  .btn:hover {
    background-color: #FFF;
    color: #54aa01;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    transform: translateY(-2px); }
  .btn:active {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transform: translateY(0); }

.j-btn {
  padding: 1.4rem;
  font-weight: 700;
  background-color: rgba(84, 170, 1, 0.7);
  color: #FFF;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  transition: .6s ease-out; }
  .j-btn:hover {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    transform: scale3d(1.1, 1.05, 1.2); }
  .j-btn a {
    text-decoration: none;
    color: #FFF; }

.carousel-wrapper {
  width: 45rem;
  height: 25.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 25px 0px rgba(46, 61, 73, 0.2);
  border-radius: 20px; }
  @media only screen and (max-width: 37.5em) {
    .carousel-wrapper {
      margin-top: 1rem; } }
  @media screen and (max-width: 17.6em) {
    .carousel-wrapper {
      box-shadow: none; } }

.carousel-container {
  width: 45rem;
  height: 25.4rem;
  overflow: hidden; }

.carousel {
  display: flex;
  width: 135rem;
  animation: sliding 12s infinite; }
  .carousel div {
    width: 45rem;
    height: 25.4rem;
    background-size: cover;
    background-position: center; }
  .carousel:hover {
    animation-play-state: paused; }
  .carousel .image-one {
    background-image: url("../public/img/didomi-2.jpg"); }
  .carousel .image-two {
    background-image: url("../public/img/didomi-3.jpg"); }
  .carousel .image-three {
    background-image: url("../public/img/didomi-4.jpg"); }

@keyframes sliding {
  30% {
    transform: translateX(0); }
  35% {
    transform: translateX(-45rem); }
  65% {
    transform: translateX(-45rem); }
  70% {
    transform: translateX(-90rem); }
  98% {
    transform: translateX(-90rem); }
  100% {
    transform: translateX(0); } }

@media screen and (max-width: 48em) {
  .carousel-wrapper {
    width: 35.1rem;
    height: 23.8rem; }
  .carousel-container {
    width: 27rem;
    height: 17rem; }
  .carousel {
    width: 81rem; }
    .carousel > div {
      width: 27rem;
      height: 17rem; }
  @keyframes sliding {
    30% {
      transform: translateX(0); }
    35% {
      transform: translateX(-27rem); }
    65% {
      transform: translateX(-27rem); }
    70% {
      transform: translateX(-54rem); }
    98% {
      transform: translateX(-54rem); }
    100% {
      transform: translateX(0); } } }

.j-carousel__wrapper {
  height: 110vh;
  position: relative;
  background-color: black; }
  @media only screen and (max-width: 75em) {
    .j-carousel__wrapper {
      height: 90vh; } }
  @media only screen and (max-width: 56.25em) {
    .j-carousel__wrapper {
      height: 60vh; } }
  @media only screen and (max-width: 37.5em) {
    .j-carousel__wrapper {
      height: 40vh; } }

.j-carousel__item {
  height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  @media only screen and (max-width: 75em) {
    .j-carousel__item {
      height: 90vh; } }
  @media only screen and (max-width: 56.25em) {
    .j-carousel__item {
      height: 60vh; } }
  @media only screen and (max-width: 37.5em) {
    .j-carousel__item {
      height: 40vh; } }

.j-carousel__item-1 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(../public/img/didomi-1.jpg);
  width: 100%;
  /* opacity: 0; */
  transition: 1.2s ease-in; }

.j-carousel__item-2 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../public/img/didomi-39.jpg");
  /* visibility: hidden; */
  width: 100%;
  opacity: 0;
  transition: 1.2s ease-in; }

.j-carousel__item-3 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../public/img/didomi-46.jpg");
  /* visibility: hidden; */
  width: 100%;
  opacity: 0;
  transition: 1.2s ease-in; }

@media only screen and (max-width: 37.5em) {
  .the-card__container {
    width: 95%; } }

.the-card {
  position: relative;
  width: 40rem;
  height: 26.4rem;
  color: #FFF;
  cursor: pointer;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 37.5em) {
    .the-card {
      width: inherit;
      margin: auto;
      margin-bottom: 3rem; } }
  .the-card__main {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 5px; }
    .the-card__main-border {
      border-bottom: 0.3rem solid #800080; }
    .the-card__main-text {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
    .the-card__main-title {
      font-size: 2rem;
      font-weight: 600; }
    .the-card__main-icon {
      font-size: 2rem; }
  .the-card__alt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background-color: #54aa01;
    color: #FFF;
    padding: 2rem;
    visibility: hidden;
    border-radius: 5px;
    transition: .2s ease-in; }
    .the-card__alt-text {
      font-size: 1.7rem;
      margin-bottom: 1.5rem;
      opacity: 0;
      transition: .2s ease-in; }
    .the-card__alt-link {
      opacity: 0;
      transition: .2s ease-in; }
    .the-card__alt-link > a, .the-card__alt a:visited, .the-card__alt a:active {
      color: #FFF;
      font-size: 1.8rem;
      font-weight: 600;
      text-decoration: none; }
    .the-card__alt-link > a:hover {
      text-decoration: underline; }
  .the-card__img-1 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../public/img/didomi-41.jpg); }
  .the-card__img-2 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../public/img/didomi-42.jpg); }
  .the-card__img-3 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../public/img/didomi-43.jpg); }
  .the-card__img-4 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../public/img/didomi-44.jpg); }
  .the-card:hover > .the-card__alt {
    visibility: visible;
    height: 100%; }
    .the-card:hover > .the-card__alt .the-card__alt-text {
      opacity: 1; }
    .the-card:hover > .the-card__alt .the-card__alt-link {
      opacity: 1; }

.the-card__mr {
  margin-right: 5rem; }
  @media only screen and (max-width: 45.6em) {
    .the-card__mr {
      margin-right: 0; } }

.card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 50.5rem;
  max-width: 110rem;
  z-index: 0;
  color: #FFF;
  margin: auto;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 75em) {
    .card {
      max-width: 90%;
      margin-bottom: 4rem; } }
  @media only screen and (max-width: 37.5em) {
    .card {
      max-width: 90%;
      margin-bottom: 5rem; } }
  .card__side {
    transition: all .8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 1rem 2rem 4rem rgba(0, 0, 0, 0.3);
    cursor: pointer; }
    .card__side--front {
      background-image: linear-gradient(to bottom right, rgba(84, 170, 1, 0.3), rgba(0, 0, 0, 0.8)), url(../public/img/didomi-5.jpg);
      background-size: cover; }
    .card__side--back {
      transform: rotateY(180deg);
      background-image: linear-gradient(to bottom right, #000, #54aa01); }
  .card:hover .card__side--front {
    background-image: linear-gradient(to bottom right, #000, #54aa01); }
  .card__heading {
    text-align: center;
    font-size: 3rem;
    padding-top: 2rem; }
  .card__details {
    font-size: 2.2rem;
    font-weight: 600;
    padding: 3rem;
    letter-spacing: 2px; }
    .card__details ul {
      width: 92%;
      margin: 0 auto; }
      .card__details ul li {
        margin-bottom: 2rem; }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .card {
      height: auto;
      border-radius: 3px;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
      .card__side {
        height: auto;
        position: relative;
        box-shadow: none; }
        .card__side--back {
          display: none; }
      .card:hover .card__side--front {
        transform: rotateY(0); }
      .card__details {
        padding: 1rem 3rem; } }

.tab {
  flex: 0 1 50%; }
  .tab-list {
    display: flex;
    margin-bottom: 3rem;
    background-color: #54aa01; }
    @media only screen and (max-width: 23.1em) {
      .tab-list {
        flex-direction: column;
        background-color: rgba(84, 170, 1, 0.7); } }
  .tab-item {
    font-size: 2rem;
    line-height: 2rem;
    color: #FFF;
    padding: 2rem; }
    @media only screen and (max-width: 37.5em) {
      .tab-item {
        font-size: 1.4rem;
        padding: .5rem; } }
    @media only screen and (max-width: 23.1em) {
      .tab-item {
        font-size: 1.6rem;
        padding: 1rem; } }
    .tab-item:hover {
      cursor: pointer; }
    .tab-item:active, .tab-item:focus {
      background-color: #FFF;
      color: #54aa01; }
  .tab-item__default-active {
    background-color: #FFF;
    color: #54aa01; }
  .tab__image-box {
    flex: 0 1 40%; }
    @media only screen and (max-width: 75em) {
      .tab__image-box {
        display: none; } }
  .tab__image-box img {
    width: 80%;
    height: 100%; }
    @media only screen and (max-width: 75em) {
      .tab__image-box img {
        display: none; } }

.service-card {
  flex: 0 1 20%;
  text-align: center;
  font-size: 1.6rem;
  color: #000;
  padding: 3rem 1rem;
  border: 0.5rem solid #54aa01;
  border-radius: .8rem;
  background-color: rgba(84, 170, 1, 0.4);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  transition: all .4s ease-out; }
  .service-card:hover {
    transform: scale3d(1.2, 1.1, 1.3); }
  .service-card__heading {
    font-size: 2rem;
    margin-bottom: .2rem; }
  .service-card__text {
    letter-spacing: .6px; }
  .service-card__icon {
    color: #54aa01;
    font-size: 4rem; }

.image-card {
  background-color: rgba(84, 170, 1, 0.4);
  padding: 2rem;
  border-radius: 2px;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.4); }
  .image-card__box {
    width: 42rem;
    height: 42rem;
    display: flex;
    border: 0.3rem solid #54aa01;
    box-sizing: border-box; }
    @media only screen and (max-width: 56.25em) {
      .image-card__box {
        width: 39rem;
        height: 39rem; } }
    @media only screen and (max-width: 56.25em) {
      .image-card__box {
        max-width: 31rem;
        max-height: 31rem; } }
  @media only screen and (max-width: 37.5em) {
    .image-card__mb {
      margin-bottom: 3rem; } }
  .image-card__img {
    max-width: 38rem;
    max-height: 38rem;
    margin: auto;
    box-sizing: border-box; }
    @media only screen and (max-width: 56.25em) {
      .image-card__img {
        max-width: 30rem;
        max-height: 30rem; } }
    .image-card__img:hover {
      transform: scale(1.08);
      z-index: -3; }
  .image-card__title {
    font-size: 2rem;
    font-weight: 600; }
  .image-card__text {
    font-size: 1.6rem; }
  .image-card__icons {
    font-size: 2rem;
    color: #54aa01; }
    @media only screen and (max-width: 56.25em) {
      .image-card__icons {
        margin-bottom: 1rem; } }
  .image-card__icon {
    color: #54aa01;
    margin-right: 1rem; }
    .image-card__icon:link, .image-card__icon:visited, .image-card__icon:active {
      text-decoration: none;
      color: #54aa01; }
    .image-card__icon:hover {
      color: #800080; }

.dropdown {
  width: 100%; }
  .dropdown__main-sub {
    font-size: 1.8rem; }
  .dropdown__main {
    position: relative;
    top: 0;
    left: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    background-color: rgba(84, 170, 1, 0.2);
    margin-bottom: .9rem;
    color: #54aa01;
    font-weight: 600;
    cursor: pointer; }
  .dropdown__sub {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all .3s ease-in; }
  .dropdown__sub-list {
    margin-left: 3%; }
    .dropdown__sub-list li {
      margin-bottom: 1rem; }
      .dropdown__sub-list li a:link, .dropdown__sub-list li a:visited, .dropdown__sub-list li a:active {
        color: #54aa01;
        text-decoration: none; }
      .dropdown__sub-list li a:hover {
        text-decoration: underline;
        color: #800080; }
  .dropdown__main-active {
    background-color: rgba(84, 170, 1, 0.7);
    color: #FFF;
    font-weight: 600; }
  .dropdown__show-sub {
    position: static;
    opacity: 1;
    margin-top: 1rem;
    margin-bottom: 1rem; }

.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s; }
  @supports (-webkit-backdrop-filter: blur(3px)) or (backdrop-filter: blur(3px)) {
    .popup {
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      background-color: rgba(0, 0, 0, 0.4); } }

.contact-form__container {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0.25);
  font-size: 2rem;
  background-color: #54aa01;
  color: #FFF;
  min-width: 35%;
  box-shadow: 0 1rem 4rem 1rem rgba(0, 0, 0, 0.4);
  padding: 4rem 1rem;
  transition: all .5s; }
  .contact-form__container-close__icon {
    position: absolute;
    top: 3rem;
    right: 2rem;
    font-size: 3rem;
    color: #FFF;
    transition: .1 ease-in; }
    .contact-form__container-close__icon:hover {
      cursor: pointer; }
    .contact-form__container-close__icon:active {
      transform: scale(0.7); }
  .contact-form__container h4 {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: .4rem; }

.contact-form {
  background-color: #FFF;
  color: #54aa01;
  font-weight: 700;
  padding: .5rem 1rem;
  border-radius: 10px;
  opacity: 0;
  transition: all .5s .2s; }
  .contact-form__row {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 3rem; }
    .contact-form__row input {
      border: none;
      border-bottom: 2px solid #54aa01;
      height: 4rem;
      font-size: 1.7rem;
      outline: none; }
    .contact-form__row textarea {
      border: none;
      border-bottom: 2px solid #54aa01;
      font-size: 1.7rem;
      outline: none;
      resize: none; }
    .contact-form__row p {
      font-size: 1.4rem;
      color: red !important; }
    .contact-form__row button {
      text-align: center;
      width: 8rem;
      height: 3.5rem;
      border-radius: 5px;
      border-color: #FFF;
      cursor: pointer;
      background-color: #54aa01;
      color: #FFF; }
      .contact-form__row button:hover, .contact-form__row button:active {
        background-color: #FFF;
        color: #54aa01;
        font-weight: 700;
        border-color: #54aa01; }
    .contact-form__row:last-child {
      align-items: center; }

.contact-button {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  padding: 2rem 2rem 2rem 2rem;
  border-radius: 50%;
  background-color: #54aa01;
  box-shadow: 0.3rem 0.4rem 0.3rem 0.3rem rgba(0, 0, 0, 0.4);
  transition: .3s ease-in; }
  .contact-button__icon {
    font-size: 3rem;
    color: #FFF;
    padding-top: .5rem; }
  .contact-button:hover {
    cursor: pointer;
    background-color: #800080;
    transform: scale(1.15); }
  .contact-button:active {
    background-color: #800080;
    transform: scale(0.5); }

.show-form {
  visibility: visible;
  opacity: 1; }
  .show-form .contact-form__container {
    transform: translateY(-50%) translateX(-50%) scale(1);
    opacity: 1; }
    .show-form .contact-form__container .contact-form {
      opacity: 1; }
    @media only screen and (max-width: 56.25em) {
      .show-form .contact-form__container {
        min-width: 55%; } }
    @media only screen and (max-width: 37.5em) {
      .show-form .contact-form__container {
        min-width: 100%;
        min-height: 100%; } }

.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.84);
  width: 100%;
  height: 100vh;
  color: #FFF;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s; }
  .search-popup__form {
    width: 50%;
    height: 6rem;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 56.25em) {
      .search-popup__form {
        width: 80%; } }
    @media only screen and (max-width: 37.5em) {
      .search-popup__form {
        width: 95%; } }
  .search-popup__input {
    width: 80%;
    height: inherit;
    border: none;
    font-size: 2.5rem;
    background-color: inherit;
    padding: 1.3rem;
    color: inherit; }
  .search-popup__input:focus {
    outline: none;
    border: none; }
  .search-popup__button {
    margin: auto;
    background-color: inherit;
    border: none; }
  .search-popup__search, .search-popup__close {
    font-size: 3rem;
    color: #FFF;
    cursor: pointer; }

.show-search-popup {
  visibility: visible;
  opacity: 1; }

.form-content {
  width: 80%;
  background-color: #FFF;
  font-size: 1.6rem;
  text-align: center;
  padding: 1.8rem;
  border-radius: 1rem;
  box-shadow: 0.6rem 0.6rem 1rem rgba(0, 0, 0, 0.3); }
  @media only screen and (max-width: 37.5em) {
    .form-content {
      width: 90%; } }
  .form-content input {
    width: 100%;
    height: 4.5rem;
    padding: 1.7rem;
    border-radius: 1rem;
    margin-bottom: 1.8rem;
    border: none;
    box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
    font-size: inherit; }
  .form-content input:focus {
    outline-color: #54aa01; }
  .form-content textarea {
    border: none;
    border-radius: 1rem;
    width: 100%;
    padding: 1.7rem;
    font-size: inherit;
    outline: none;
    resize: none;
    margin-bottom: 1.8rem;
    box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.15); }
    .form-content textarea:focus {
      border: 2px solid #54aa01; }
  .form-content button {
    width: 100%;
    height: 4.5rem;
    font-size: 2rem;
    border-radius: 1rem;
    border-color: #54aa01;
    margin-top: .6rem;
    margin-bottom: 1rem;
    color: #FFF;
    background-color: #54aa01;
    cursor: pointer; }
    .form-content button:hover {
      background-color: rgba(84, 170, 1, 0.8); }
  .form-content__forgot-password {
    width: 100%;
    border-bottom: 1px solid #000;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    padding-bottom: 1.7rem;
    color: #54aa01; }
  .form-content__error-message {
    color: red; }
  .form-content__select {
    width: 100%;
    height: 4.5rem;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1.8rem;
    border: none;
    box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
    font-size: inherit; }
    .form-content__select:focus {
      outline-color: #54aa01; }
  .form-content__policy {
    display: flex;
    font-weight: 600; }
    .form-content__policy input {
      flex: 0 1 10%;
      height: 2rem;
      margin-right: 1rem;
      padding: 0;
      box-shadow: none;
      margin-top: .3rem; }
    .form-content__policy input:checked {
      accent-color: #800080; }

.apply-btn {
  width: 50%;
  height: 4.5rem;
  font-size: 1.8rem;
  border-radius: 1rem;
  border-color: #800080;
  margin: auto;
  margin-top: .4rem;
  color: #FFF;
  background-color: #800080;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .apply-btn:hover {
    background-color: rgba(128, 0, 128, 0.8); }
  .apply-btn a {
    text-decoration: none;
    color: inherit; }

.email-form {
  width: 80%;
  background-color: #FFF;
  font-size: 1.6rem;
  padding: 1.8rem;
  border-radius: 1rem;
  box-shadow: 0.6rem 0.6rem 1rem rgba(0, 0, 0, 0.3);
  margin-bottom: 3rem; }
  @media only screen and (max-width: 37.5em) {
    .email-form {
      width: 90%; } }
  @media only screen and (max-width: 16em) {
    .email-form {
      text-align: center; } }
  .email-form label {
    margin-right: 1rem; }
  .email-form input {
    width: 70%;
    height: 3rem;
    padding: 1.7rem;
    border-radius: 1rem;
    margin-bottom: 1.8rem;
    border: none;
    box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
    font-size: inherit; }
  .email-form input:focus {
    outline-color: #54aa01; }
  .email-form__add {
    margin-left: 1rem;
    border-radius: .4rem;
    border: none;
    cursor: pointer;
    background-color: #54aa01;
    color: #FFF; }
    @media only screen and (max-width: 16em) {
      .email-form__add {
        text-align: center; } }
    .email-form__add i {
      font-size: 2rem; }
    .email-form__add:hover {
      background-color: #800080;
      color: #FFF; }
    .email-form__add:active {
      background-color: #2E1114;
      color: #FFF; }
  .email-form__emails {
    display: flex;
    margin-left: 5%; }
    @media only screen and (max-width: 16em) {
      .email-form__emails {
        flex-direction: column; } }
    .email-form__emails p {
      color: #800080; }
      @media only screen and (max-width: 16em) {
        .email-form__emails p {
          font-size: 1.4rem; } }
  .email-form__delete {
    margin-left: 1rem;
    border: none;
    cursor: pointer;
    background-color: #FFF;
    color: #54aa01; }
    .email-form__delete i {
      font-size: 2rem; }
      @media only screen and (max-width: 16em) {
        .email-form__delete i {
          font-size: 1.4rem; } }
    .email-form__delete:hover {
      background-color: #800080;
      color: #FFF; }
    .email-form__delete:active {
      background-color: #2E1114;
      color: #FFF; }

.footer {
  background-color: #333;
  color: #FFF;
  font-size: 1.6rem;
  padding-top: 5rem; }
  .footer__top {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 37.5em) {
      .footer__top {
        flex-direction: column;
        align-items: flex-start; } }
  .footer__bottom {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 37.5em) {
      .footer__bottom {
        flex-direction: column;
        align-items: flex-start; } }
  .footer__company-info {
    margin-left: 6rem; }
    @media only screen and (max-width: 37.5em) {
      .footer__company-info {
        margin-bottom: 4rem;
        margin-left: 2rem; } }
  .footer__logo-box {
    width: 20rem;
    height: 15rem; }
    .footer__logo-box img {
      width: 100%;
      height: 100%; }
  @media only screen and (max-width: 56.25em) {
    .footer {
      font-size: 1.4rem; } }
  @media only screen and (max-width: 37.5em) {
    .footer {
      font-size: 1.8rem; }
      .footer__logo-box img {
        width: 15rem; } }
  .footer__contact-info a:link {
    text-decoration: none;
    color: #bfbfc1; }
  @media only screen and (max-width: 37.5em) {
    .footer__contact-info {
      font-size: 1.5rem; } }
  .footer__nav:last-child {
    margin-right: 6rem; }
  @media only screen and (max-width: 56.25em) {
    .footer__nav {
      margin-left: 2rem;
      margin-bottom: 4rem; } }
  .footer__header {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 3rem; }
    @media only screen and (max-width: 56.25em) {
      .footer__header {
        font-size: 1.7rem;
        margin-bottom: 1.5rem; } }
    @media only screen and (max-width: 37.5em) {
      .footer__header {
        font-size: 2rem;
        margin-bottom: 1rem; } }
  .footer__item {
    list-style-type: none; }
    .footer__item:not(:last-child) {
      margin-bottom: .8rem; }
  .footer__links {
    text-decoration: none;
    color: #bfbfc1; }
    .footer__links:hover, .footer__links:visited, .footer__links:active {
      color: #FFF; }
  .footer__icons {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 6rem; }
    @media only screen and (max-width: 37.5em) {
      .footer__icons {
        margin-left: 2rem; } }
  .footer__icon {
    font-size: 2.2rem;
    margin-right: 1.2rem;
    margin-top: 0;
    color: #FFF; }
    .footer__icon:hover, .footer__icon:visited, .footer__icon:active {
      color: #54aa01; }
  .footer__copyright {
    margin-right: 6rem;
    margin-bottom: .4rem;
    align-self: flex-end; }
    @media only screen and (max-width: 37.5em) {
      .footer__copyright {
        align-self: flex-start;
        margin-left: 2rem;
        margin-top: 3rem;
        margin-right: 0; } }

.content {
  right: 0;
  width: inherit;
  margin: 0; }

.home {
  background-color: #FAF9F6;
  color: #FFF;
  margin-top: 9.5rem; }
  @media only screen and (max-width: 37.5em) {
    .home {
      margin-top: 7.5rem; } }

.home-header {
  height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../public/img/didomi-1.jpg);
  background-size: cover; }
  @media only screen and (max-width: 75em) {
    .home-header {
      height: 90vh; } }
  @media only screen and (max-width: 56.25em) {
    .home-header {
      height: 60vh; } }
  @media only screen and (max-width: 37.5em) {
    .home-header {
      height: 40vh; } }

.section-1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  margin: 8rem 1rem; }
  @media only screen and (max-width: 37.5em) {
    .section-1 {
      flex-direction: column; } }
  .section-1__text-box {
    flex: 0 1 50%;
    align-self: baseline; }
  .section-1__img-box {
    flex: 0 1 35%; }

.section-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 8rem; }
  .section-2__heading-box {
    margin-bottom: 4rem; }
    @media only screen and (max-width: 56.25em) {
      .section-2__heading-box {
        margin-bottom: 0; } }
    @media only screen and (max-width: 37.5em) {
      .section-2__heading-box {
        margin-bottom: 0; } }
  .section-2__row-1 {
    display: flex;
    justify-content: center;
    width: 90%;
    border-bottom: 0.5rem solid #54aa01;
    margin-bottom: 1.5rem; }
    @media only screen and (max-width: 56.25em) {
      .section-2__row-1 {
        flex-direction: column;
        height: 90vh; } }
    @media only screen and (max-width: 37.5em) {
      .section-2__row-1 {
        height: 70vh; } }
  .section-2__row-1-img {
    flex: 0 1 40%;
    margin-bottom: 2rem;
    height: 35rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    transition: all .4s ease-in; }
    @media only screen and (max-width: 56.25em) {
      .section-2__row-1-img {
        background-repeat: no-repeat;
        background-size: cover; } }
  .section-2__row-1-img-1 {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.6)), url(../public/img/didomi-28.jpg);
    margin-right: 3rem; }
    @media only screen and (max-width: 56.25em) {
      .section-2__row-1-img-1 {
        margin-bottom: 6rem; } }
  .section-2__row-1-img-1:hover {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.5)), url(../public/img/didomi-28.jpg);
    cursor: pointer; }
  .section-2__row-1-img-1:hover .section-2__row-1-img-sub-text {
    visibility: visible;
    opacity: 1;
    margin-top: -.5rem;
    margin-bottom: 6rem; }
  .section-2__row-1-img-2 {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.6)), url(../public/img/didomi-29.jpg); }
  .section-2__row-1-img-2:hover {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.5)), url(../public/img/didomi-29.jpg);
    cursor: pointer; }
  .section-2__row-1-img-2:hover .section-2__row-1-img-sub-text {
    visibility: visible;
    opacity: 1;
    margin-top: -.5rem;
    margin-bottom: 6rem; }
  .section-2__row-1-img-text {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 600; }
    @media only screen and (max-width: 22.1em) {
      .section-2__row-1-img-text {
        font-size: 2rem; } }
  .section-2__row-1-img-sub-text {
    visibility: hidden;
    opacity: 0;
    margin-bottom: -6rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    transition: all .4s ease-in; }
  .section-2__special-box {
    background-color: rgba(84, 170, 1, 0.6);
    margin-top: 3.5rem;
    padding: 1.5rem;
    border-radius: 3rem;
    transition: all .4s ease-in; }
    .section-2__special-box:hover {
      transform: scaleX(1.1);
      cursor: pointer; }
  .section-2__special-box-heading > a:link, .section-2 a:visited {
    text-decoration: none;
    color: #FFF; }

.section-3 {
  background-color: rgba(84, 170, 1, 0.1);
  margin-top: -7rem;
  padding-top: 10rem;
  padding-bottom: 4rem;
  clip-path: polygon(0% 0%, 12% 0%, 12% 8%, 88% 8%, 88% 0%, 100% 0%, 100% 100%, 0% 100%); }
  @media only screen and (max-width: 75em) {
    .section-3 {
      clip-path: polygon(0% 0%, 3% 0%, 3% 5%, 97% 5%, 97% 0%, 100% 0%, 100% 100%, 0% 100%); } }
  .section-3__heading-border {
    border-bottom: 0.6rem solid #54aa01;
    width: 10%;
    margin: auto;
    margin-bottom: 4rem; }
  .section-3__heading-sub {
    width: 70%;
    margin: auto; }
    @media only screen and (max-width: 37.5em) {
      .section-3__heading-sub {
        width: 90%; } }
  .section-3__row-1 {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-bottom: 0.5rem solid #54aa01;
    margin-left: 5%;
    margin-right: 5%; }
    @media only screen and (max-width: 37.5em) {
      .section-3__row-1 {
        flex-direction: column;
        text-align: center; } }
  .section-3__row-1-part-1 {
    flex: 0 1 45%; }
    .section-3__row-1-part-1 img {
      width: 85%;
      border-radius: 2rem; }
    @media only screen and (max-width: 37.5em) {
      .section-3__row-1-part-1 img {
        width: 100%; } }
  .section-3__row-1-part-2 {
    flex: 0 1 45%;
    margin-bottom: 3rem; }
    .section-3__row-1-part-2 img {
      width: 85%;
      border-radius: 2rem; }
    @media only screen and (max-width: 37.5em) {
      .section-3__row-1-part-2 {
        margin-top: 3rem; }
        .section-3__row-1-part-2 img {
          width: 100%; } }
  .section-3__row-2 {
    display: flex;
    margin-top: 3rem;
    justify-content: center; }
    @media only screen and (max-width: 56.25em) {
      .section-3__row-2 {
        flex-direction: column;
        margin-left: 3%;
        margin-right: 3%; } }
  .section-3__row-2-col {
    flex: 0 1 25%; }
    @media only screen and (max-width: 56.25em) {
      .section-3__row-2-col {
        display: flex; } }
    @media only screen and (max-width: 37.5em) {
      .section-3__row-2-col {
        flex-direction: column; } }
    .section-3__row-2-col a {
      text-decoration: none;
      color: inherit; }
  .section-3__row-2-img {
    height: 24rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: all .4s ease-in; }
  .section-3__row-2-img:hover {
    transform: scale(1.1); }
    @media only screen and (max-width: 37.5em) {
      .section-3__row-2-img:hover {
        transform: scale(0.9); } }
  @media only screen and (max-width: 37.5em) {
    .section-3__row-2-col div {
      margin-bottom: 3rem; } }
  .section-3__row-2-img-1 {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.6)), url(../public/img/didomi-32.jpg); }
  .section-3__row-2-img-2 {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.6)), url(../public/img/didomi-33.jpg); }
  .section-3__row-2-img-3 {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.6)), url(../public/img/didomi-34.jpg); }
  .section-3__row-2-img-4 {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.6)), url(../public/img/didomi-35.jpg); }
  .section-3__row-2-img-5 {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.6)), url(../public/img/didomi-36.jpg); }
  .section-3__row-2-img-6 {
    background-image: linear-gradient(to bottom, rgba(84, 170, 1, 0.6), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(84, 170, 1, 0.6)), url(../public/img/didomi-37.jpg); }
  .section-3__row-2-img-text {
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 3rem; }
  .section-3__row-2-text {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 3rem;
    margin-bottom: 3rem; }
  .section-3__row-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .section-3__special-box {
    background-color: rgba(84, 170, 1, 0.6);
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    padding: 1.5rem;
    border-radius: 3rem;
    transition: all .4s ease-in; }
    .section-3__special-box:hover {
      transform: scaleX(1.1);
      cursor: pointer; }

.section-5 {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .section-5__content-box {
    width: 88%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(84, 170, 1, 0.25);
    padding: 3rem;
    margin-bottom: 4rem; }
    @media only screen and (max-width: 37.5em) {
      .section-5__content-box {
        flex-direction: column; } }
  .section-5__heading-sub {
    text-align: center; }
  .section-5__text {
    flex: 0 1 50%; }
  .section-5__img-box {
    flex: 0 1 45%; }
    .section-5__img-box img {
      width: 85%;
      border-radius: 2px;
      box-shadow: 1rem 2rem 4rem rgba(0, 0, 0, 0.5); }
    @media only screen and (max-width: 37.5em) {
      .section-5__img-box img {
        margin-top: 2rem; } }

.apply__btn {
  background-color: #FFF;
  border: 2px solid #54aa01;
  border-radius: .8rem;
  font-size: 2rem;
  padding: .1rem 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center; }

.apply__btn a {
  text-decoration: none;
  color: #54aa01; }

.apply__btn:hover {
  cursor: pointer; }

.apply__btn:hover > a {
  text-decoration: underline;
  color: #800080; }

.technology {
  margin-top: 12rem; }
  .technology__mins {
    position: fixed;
    left: 3rem;
    bottom: 3rem;
    border-radius: 50%;
    padding: 2rem;
    text-align: center;
    background-color: #000;
    font-size: 1.6rem;
    font-weight: 700;
    color: #FFF;
    box-shadow: 0.3rem 0.4rem 0.3rem 0.3rem rgba(0, 0, 0, 0.4);
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    z-index: 1000;
    transition: 0.7s ease-in; }
    .technology__mins:hover {
      background-color: #800080; }
    .technology__mins:active {
      background-color: #54aa01;
      color: #FFF; }
  .technology__header {
    height: 110vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.8)), url(../public/img/didomi-48.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #FFF;
    padding-left: 15%; }
    @media only screen and (max-width: 37.5em) {
      .technology__header {
        height: 100vh;
        flex-direction: column;
        justify-content: center;
        padding-left: 3%; } }
  .technology__header-text {
    flex: 0 1 60%; }
  .technology__header-sub {
    font-size: 1.8rem;
    letter-spacing: .5rem;
    margin-bottom: 4rem;
    margin-left: -5%;
    color: #54aa01; }
    @media only screen and (max-width: 37.5em) {
      .technology__header-sub {
        margin-left: 0%;
        font-size: 1.8rem; } }
  .technology__header-main {
    margin-bottom: 2rem; }
  .technology__header-paragraph {
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.8); }
  .technology__header-time {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 3%;
    margin-bottom: 3%; }
  .technology__seconds {
    font-size: 4rem;
    color: #54aa01;
    font-weight: 600;
    opacity: 0;
    transition: 0.6s ease-in; }
  .technology__time {
    font-size: 2rem;
    color: #800080;
    font-weight: 600; }

.tech-sect-2 {
  height: 100vh;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15%; }
  @media only screen and (max-width: 56.25em) {
    .tech-sect-2 {
      height: inherit;
      padding-top: 10%;
      padding-bottom: 10%; } }
  @media only screen and (max-width: 37.5em) {
    .tech-sect-2 {
      justify-content: center;
      padding-left: 3%; } }
  .tech-sect-2__div-1 {
    flex: 0 1 60%; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-2__div-1 {
        flex: 0 1 100%; } }
  .tech-sect-2__text-sub {
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    margin-bottom: 4rem;
    margin-left: -5%;
    color: #54aa01; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-2__text-sub {
        font-size: 1.8rem;
        margin-left: 0%; } }
  .tech-sect-2__text-main {
    margin-bottom: 4rem; }
  .tech-sect-2__div-2 {
    flex: 0 1 30%; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-2__div-2 {
        display: none; } }
    .tech-sect-2__div-2 img {
      width: 50%; }
  .tech-sect-2__btn {
    margin-right: 2rem; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-2__btn {
        text-align: center; }
        .tech-sect-2__btn:first-child {
          margin-bottom: 2rem; } }

.tech-sect-3 {
  width: 0;
  opacity: 0;
  height: 100vh;
  background-color: rgba(84, 170, 1, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15%;
  transition: 1s ease-out; }
  @media only screen and (max-width: 37.5em) {
    .tech-sect-3 {
      flex-direction: column;
      justify-content: center;
      padding-left: 3%; } }
  .tech-sect-3__div-1 {
    flex: 0 1 60%;
    opacity: 0;
    visibility: hidden;
    transition-delay: 0.9s; }
  .tech-sect-3__text-sub {
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    margin-bottom: 4rem;
    margin-left: -5%;
    color: #FFF; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-3__text-sub {
        margin-left: 0%;
        font-size: 1.8rem; } }
  .tech-sect-3__text-main {
    margin-bottom: 4rem; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-3__text-main {
        margin-bottom: 0; } }
  .tech-sect-3__div-2 {
    flex: 0 1 30%;
    opacity: 0; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-3__div-2 {
        text-align: center; } }
    .tech-sect-3__div-2 img {
      width: 50%; }

.show-tech-sect-3 {
  width: 100%;
  opacity: 1; }
  .show-tech-sect-3 .tech-sect-3__div-1 {
    opacity: 1;
    visibility: visible; }
  .show-tech-sect-3 .tech-sect-3__div-2 {
    opacity: 1; }

.tech-section {
  height: 110vh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../public/img/didomi-50.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 37.5em) {
    .tech-section {
      height: 40vh; } }
  .tech-section h2 {
    flex: 0 1 70%; }
    @media only screen and (max-width: 37.5em) {
      .tech-section h2 {
        flex: 0 1 90%; } }

.tech-sect-4 {
  position: relative;
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20% 10%;
  transition: 1s ease-out; }
  @media only screen and (max-width: 37.5em) {
    .tech-sect-4 {
      flex-direction: column;
      justify-content: center;
      padding: 10% 10%;
      padding-left: 3%; } }
  .tech-sect-4__div-1-imaginary {
    flex: 0 1 50%;
    opacity: 0;
    visibility: hidden;
    transition: 1s ease-out; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-4__div-1-imaginary {
        flex: 0 1 100%;
        opacity: 1;
        visibility: visible; } }
  .tech-sect-4__div-1 {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 30%;
    transition: 1s ease-out; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-4__div-1 {
        display: none; } }
    .tech-sect-4__div-1 h2 {
      height: 0;
      opacity: 0;
      transition-delay: 1.2s; }
  .tech-sect-4__text-sub {
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    margin-bottom: 4rem;
    color: #54aa01; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-4__text-sub {
        font-size: 1.8rem; } }
  .tech-sect-4__text-main {
    margin-bottom: 4rem; }
  .tech-sect-4__div-2 {
    flex: 0 1 50%; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-4__div-2 {
        flex: 0 1 100%; } }
  .tech-sect-4__paragraph {
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.8); }

.show-div-1 {
  position: fixed;
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
  top: 50%; }
  .show-div-1 h2 {
    height: inherit;
    opacity: 1; }

.hide-div-1 {
  opacity: 0;
  visibility: hidden;
  top: 0;
  transform: none; }

.tech-sect-5 {
  height: 110vh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../public/img/didomi-49.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  @media only screen and (max-width: 37.5em) {
    .tech-sect-5 {
      height: 40vh; } }

.tech-sect-6 {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20% 10%;
  transition: 1s ease-out; }
  @media only screen and (max-width: 37.5em) {
    .tech-sect-6 {
      flex-direction: column;
      justify-content: center;
      padding: 10% 10%;
      padding-left: 3%; } }
  .tech-sect-6__div-1-imaginary {
    flex: 0 1 50%;
    opacity: 0;
    visibility: hidden;
    transition: 1s ease-out; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-6__div-1-imaginary {
        flex: 0 1 100%;
        opacity: 1;
        visibility: visible; } }
  .tech-sect-6__div-1 {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 30%;
    transition: 1s ease-out; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-6__div-1 {
        display: none; } }
    .tech-sect-6__div-1 h2 {
      height: 0;
      opacity: 0;
      transition-delay: 1.2s; }
  .tech-sect-6__text-sub {
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
    margin-bottom: 4rem;
    color: #54aa01; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-6__text-sub {
        font-size: 1.8rem; } }
  .tech-sect-6__text-main {
    margin-bottom: 4rem; }
  .tech-sect-6__div-2 {
    flex: 0 1 50%; }
    @media only screen and (max-width: 37.5em) {
      .tech-sect-6__div-2 {
        flex: 0 1 100%; } }
  .tech-sect-6__paragraph {
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.8); }

.show-6-div-1 {
  position: fixed;
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
  top: 50%; }
  .show-6-div-1 h2 {
    height: inherit;
    opacity: 1; }

.hide-6-div-1 {
  opacity: 0;
  visibility: hidden;
  top: 0;
  transform: none; }

@media only screen and (max-width: 37.5em) {
  .tech-sect-7 {
    height: 42vh; } }

@media only screen and (max-width: 37.5em) {
  .tech-sect-7__img-none {
    display: none; } }

.search-result {
  background-color: rgba(84, 170, 1, 0.1);
  color: #000;
  margin-top: 12rem;
  margin-bottom: -2rem; }
  .search-result__header {
    display: flex;
    align-items: center;
    height: 70vh;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url(../public/img/didomi-45.jpg);
    background-size: cover;
    color: #FFF; }
    @media only screen and (max-width: 37.5em) {
      .search-result__header {
        height: 40vh;
        align-items: flex-end; } }
  .search-result__header-text {
    margin-left: 6%; }
    @media only screen and (max-width: 37.5em) {
      .search-result__header-text {
        margin-bottom: 5%; } }
  .search-result__header-text-main {
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out; }
    @media only screen and (max-width: 37.5em) {
      .search-result__header-text-main {
        font-size: 2.5rem; } }
    @media only screen and (max-width: 22.1em) {
      .search-result__header-text-main {
        font-size: 1.5rem; } }
  .search-result__header-text-sub {
    width: 50%;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out; }
    @media only screen and (max-width: 37.5em) {
      .search-result__header-text-sub {
        width: 75%;
        font-size: 1.5rem;
        line-height: 1.8rem; } }
    @media only screen and (max-width: 22.1em) {
      .search-result__header-text-sub {
        font-size: 1rem;
        line-height: 1.2rem; } }
  .search-result__content {
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 6rem;
    padding-bottom: 6rem; }
  .search-result__content-heading-2 h3 a {
    color: #800080; }
  .search-result__item {
    margin-bottom: 3rem;
    margin-left: 3%; }

.about-us {
  background-color: rgba(84, 170, 1, 0.1);
  color: #000;
  margin-top: 12rem;
  margin-bottom: -2rem; }
  .about-us__header {
    display: flex;
    align-items: center;
    height: 120vh;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url(../public/img/didomi-39.jpg);
    background-size: cover;
    color: #FFF; }
    @media only screen and (max-width: 75em) {
      .about-us__header {
        height: 90vh; } }
    @media only screen and (max-width: 37.5em) {
      .about-us__header {
        height: 40vh;
        align-items: flex-end; } }
  .about-us__header-text {
    margin-left: 6%; }
    @media only screen and (max-width: 37.5em) {
      .about-us__header-text {
        margin-bottom: 5%; } }
  .about-us__header-text-main {
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out; }
    @media only screen and (max-width: 37.5em) {
      .about-us__header-text-main {
        font-size: 2.5rem; } }
    @media only screen and (max-width: 22.1em) {
      .about-us__header-text-main {
        font-size: 1.5rem; } }
  .about-us__header-text-sub {
    width: 50%;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out; }
    @media only screen and (max-width: 37.5em) {
      .about-us__header-text-sub {
        width: 75%;
        font-size: 1.5rem;
        line-height: 1.8rem; } }
    @media only screen and (max-width: 22.1em) {
      .about-us__header-text-sub {
        font-size: 1rem;
        line-height: 1.2rem; } }
  .about-us__heading-sub {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
    margin-bottom: 0; }
  .about-us__heading-border {
    border-bottom: 0.3rem solid #54aa01;
    width: 19%;
    margin: auto;
    margin-bottom: 4rem; }
    @media only screen and (max-width: 56.25em) {
      .about-us__heading-border {
        width: 22%; } }
    @media only screen and (max-width: 37.5em) {
      .about-us__heading-border {
        width: 36%; } }
  .about-us__paragraph {
    width: 60%;
    text-align: center;
    margin: auto;
    margin-bottom: 3rem;
    font-weight: 600; }
    @media only screen and (max-width: 56.25em) {
      .about-us__paragraph {
        width: 80%; } }
    @media only screen and (max-width: 37.5em) {
      .about-us__paragraph {
        width: 90%; } }

.didomi-manager__header {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem; }

.didomi-manager__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20rem; }
  @media only screen and (max-width: 56.25em) {
    .didomi-manager__info {
      flex-direction: column-reverse; } }

.didomi-manager__text {
  flex: 0 1 60%;
  margin-left: 6rem; }
  @media only screen and (max-width: 56.25em) {
    .didomi-manager__text {
      margin-left: 2rem; } }

.didomi-manager__section-1 {
  border-bottom: 3px solid #54aa01;
  margin-bottom: 4rem; }
  .didomi-manager__section-1 p {
    margin-bottom: 4rem; }

.didomi-manager__section-2 {
  border-bottom: 3px solid #54aa01;
  margin-bottom: 4rem; }
  .didomi-manager__section-2 p {
    margin-top: 2rem;
    margin-bottom: 4rem; }

.didomi-manager__section-3 ul {
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 4rem; }

@media only screen and (max-width: 56.25em) {
  .didomi-manager__section-3 {
    margin-left: 3rem; } }

.didomi-manager__img-box {
  flex: 0 1 30%; }
  .didomi-manager__img-box img {
    margin-right: 6rem; }
    @media only screen and (max-width: 37.5em) {
      .didomi-manager__img-box img {
        width: 85%; } }
    @media only screen and (max-width: 22.1em) {
      .didomi-manager__img-box img {
        width: 70%; } }
  @media only screen and (max-width: 56.25em) {
    .didomi-manager__img-box {
      margin-left: 5rem;
      margin-bottom: 2rem; } }

.didomi-manager__name {
  font-size: 3rem;
  font-weight: 900; }
  @media only screen and (max-width: 37.5em) {
    .didomi-manager__name {
      font-size: 2rem; } }

.didomi-manager__title {
  font-size: 1.8rem;
  font-weight: 500; }

.didomi-safety {
  background-color: rgba(84, 170, 1, 0.1);
  color: #000;
  box-sizing: border-box;
  margin-top: 12rem;
  margin-bottom: -6.5rem; }
  .didomi-safety__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url(../public/img/didomi-8.jpg);
    background-size: cover;
    color: #FFF; }
    @media only screen and (max-width: 75em) {
      .didomi-safety__header {
        height: 90vh; } }
    @media only screen and (max-width: 37.5em) {
      .didomi-safety__header {
        height: 40vh; } }
  .didomi-safety__header-text {
    font-size: 4.5rem;
    font-weight: 600;
    letter-spacing: .7rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out; }
    @media only screen and (max-width: 37.5em) {
      .didomi-safety__header-text {
        font-size: 2.5rem;
        text-align: center; } }
    @media only screen and (max-width: 22.1em) {
      .didomi-safety__header-text {
        font-size: 1.5rem; } }
  .didomi-safety__section {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .didomi-safety__section {
        flex-direction: column;
        margin-bottom: 5rem; } }
  .didomi-safety__section-part-1 {
    flex: 0 1 45%;
    margin-right: 8rem;
    padding: 3rem;
    border-radius: 3px;
    background-image: linear-gradient(to right bottom, rgba(128, 0, 128, 0.4), rgba(84, 170, 1, 0.5)); }
    .didomi-safety__section-part-1 p {
      font-weight: 600;
      color: #2E1114;
      margin: auto; }
    .didomi-safety__section-part-1 h5 {
      margin-bottom: 1.5rem; }
    .didomi-safety__section-part-1 ul li {
      font-weight: 600;
      color: #2E1114;
      margin-bottom: .5rem; }
    @media only screen and (max-width: 56.25em) {
      .didomi-safety__section-part-1 {
        width: 90%;
        margin: auto;
        margin-bottom: 6rem; } }
  .didomi-safety__contact-para {
    font-size: 1.6rem; }
    @media only screen and (max-width: 22.1em) {
      .didomi-safety__contact-para {
        font-size: 1.3rem; } }
  .didomi-safety__section-part-2 {
    flex: 0 1 40%; }
    .didomi-safety__section-part-2 div img {
      box-shadow: 1rem 2rem 4rem rgba(0, 0, 0, 0.3);
      border-radius: 5px; }
      @media only screen and (max-width: 56.25em) {
        .didomi-safety__section-part-2 div img {
          width: 100%; } }
    @media only screen and (max-width: 56.25em) {
      .didomi-safety__section-part-2 {
        width: 90%;
        margin: auto;
        margin-bottom: 3rem; } }
  .didomi-safety__section-img-box {
    box-shadow: 1rem 2rem 4rem rgba(0, 0, 0, 0.3); }
    @media only screen and (max-width: 37.5em) {
      .didomi-safety__section-img-box img {
        width: 90%; } }
  .didomi-safety__section-link:link, .didomi-safety__section-link:visited, .didomi-safety__section-link:active {
    color: #2E1114; }

.didomi {
  background-color: rgba(0, 0, 0, 0.8);
  color: #000;
  margin-bottom: -7rem;
  margin-top: 12rem; }
  .didomi__header {
    display: flex;
    align-items: center;
    height: 120vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(../public/img/didomi-40.jpg);
    background-size: cover;
    color: #FFF; }
    @media only screen and (max-width: 75em) {
      .didomi__header {
        height: 90vh; } }
    @media only screen and (max-width: 56.25em) {
      .didomi__header {
        height: 70vh; } }
    @media only screen and (max-width: 37.5em) {
      .didomi__header {
        height: 40vh;
        align-items: flex-end; } }
  .didomi__header-text {
    margin-left: 6%; }
    @media only screen and (max-width: 37.5em) {
      .didomi__header-text {
        margin-bottom: 5%; } }
  .didomi__header-text-main {
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: .2rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out; }
    @media only screen and (max-width: 37.5em) {
      .didomi__header-text-main {
        font-size: 2.5rem; } }
    @media only screen and (max-width: 22.1em) {
      .didomi__header-text-main {
        font-size: 1.5rem; } }
  .didomi__header-text-sub {
    width: 50%;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3.5rem;
    color: rgba(255, 255, 255, 0.8);
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out; }
    @media only screen and (max-width: 56.25em) {
      .didomi__header-text-sub {
        line-height: 3.7rem; } }
    @media only screen and (max-width: 37.5em) {
      .didomi__header-text-sub {
        width: 80%;
        font-size: 1.7rem;
        line-height: 2.2rem; } }
    @media only screen and (max-width: 22.1em) {
      .didomi__header-text-sub {
        font-size: 1rem;
        line-height: 1.7rem; } }

.didomi-service {
  margin-top: 4rem;
  color: #000; }
  .didomi-service__header {
    display: flex;
    justify-content: center;
    margin-top: 9rem;
    margin-bottom: 3px; }
  .didomi-service__heading-border {
    border-bottom: 0.3rem solid #54aa01;
    width: 19%;
    margin: auto;
    margin-bottom: 4rem; }
    @media only screen and (max-width: 56.25em) {
      .didomi-service__heading-border {
        width: 22%; } }
    @media only screen and (max-width: 37.5em) {
      .didomi-service__heading-border {
        width: 36%; } }
  .didomi-service__paragraph {
    width: 60%;
    text-align: center;
    margin: auto;
    margin-bottom: 4rem;
    font-weight: 600; }
    @media only screen and (max-width: 56.25em) {
      .didomi-service__paragraph {
        width: 80%; } }
    @media only screen and (max-width: 37.5em) {
      .didomi-service__paragraph {
        width: 90%; } }
  .didomi-service__row {
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 45.6em) {
      .didomi-service__row {
        flex-direction: column;
        align-items: center; } }

.part-institution {
  width: 100%;
  margin-top: 3rem;
  padding-top: 3rem;
  padding-bottom: .1rem; }
  .part-institution__container {
    max-width: 94%;
    margin: auto; }

.applications {
  display: flex;
  justify-content: space-evenly;
  margin-top: 4rem;
  margin-bottom: 9rem;
  padding-bottom: 2rem;
  color: #FFF; }
  @media only screen and (max-width: 37.5em) {
    .applications {
      flex-direction: column; } }
  .applications__rating {
    text-align: center; }
    @media only screen and (max-width: 37.5em) {
      .applications__rating {
        margin-bottom: 4rem; } }
  .applications__rating-value {
    font-size: 3rem;
    font-weight: 600; }
  .applications__rating-text {
    font-size: 1.6rem; }

.didomi-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 2rem 0; }
  .didomi-partners__heading-box {
    margin-bottom: 4rem; }
    @media only screen and (max-width: 37.5em) {
      .didomi-partners__heading-box {
        margin-bottom: 4rem; } }
  .didomi-partners__box {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 8rem; }
    @media only screen and (max-width: 75em) {
      .didomi-partners__box {
        flex-direction: column;
        width: 90%;
        margin-bottom: 1rem; } }

.scholarship {
  background-color: #FAF9F6;
  margin-top: 12rem; }
  .scholarship__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    height: 120vh;
    margin-bottom: 5rem;
    padding-bottom: 9rem;
    background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../public/img/didomi-16.jpg);
    background-size: cover;
    color: #FFF; }
    @media only screen and (max-width: 44.5em) {
      .scholarship__header {
        height: 90vh;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 0; } }
    @media only screen and (max-width: 56.25em) {
      .scholarship__header {
        height: 70vh; } }
  .scholarship__heading-main {
    flex: 0 1 30%;
    margin-top: 0;
    font-size: 3rem; }
    @media only screen and (max-width: 44.5em) {
      .scholarship__heading-main {
        width: 60%;
        max-height: 12rem; } }
  .scholarship__heading-sub {
    flex: 0 1 60%;
    font-size: 1.8rem;
    color: rgba(255, 255, 255, 0.8); }
    @media only screen and (max-width: 44.5em) {
      .scholarship__heading-sub {
        width: 60%; } }
  .scholarship__packages-main-header {
    display: flex;
    margin: 4rem 3%;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6rem;
    margin-bottom: 3rem; }
    .scholarship__packages-main-header i {
      font-size: 4rem;
      color: #54aa01;
      margin-right: 2rem; }
  .scholarship__packages {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin-left: 3%;
    margin-right: 3%; }
    @media only screen and (max-width: 37.5em) {
      .scholarship__packages {
        flex-direction: column; } }
  .scholarship__package {
    flex: 0 1 31%; }
    .scholarship__package h3 {
      margin-bottom: 1.3rem; }
      @media only screen and (max-width: 37.5em) {
        .scholarship__package h3 {
          margin-bottom: .5rem; } }
    @media only screen and (max-width: 37.5em) {
      .scholarship__package {
        margin-bottom: 2.1rem; } }

.scholarship__details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8rem;
  padding-top: 7rem;
  padding-bottom: 7rem;
  padding-left: 5%;
  background-color: rgba(84, 170, 1, 0.1); }
  @media only screen and (max-width: 37.5em) {
    .scholarship__details {
      flex-direction: column; } }
  .scholarship__details-text {
    flex: 0 1 40%; }
    .scholarship__details-text ul {
      border-bottom: 0.3rem solid #54aa01; }
    .scholarship__details-text ul li {
      margin-bottom: 1rem; }
    @media only screen and (max-width: 37.5em) {
      .scholarship__details-text {
        margin-bottom: 2rem; } }
  .scholarship__details-img-box {
    flex: 0 1 52%;
    background-color: rgba(84, 170, 1, 0.2);
    padding: 4rem;
    border-bottom: 0.3rem solid #54aa01; }
    .scholarship__details-img-box-2 {
      text-align: center;
      background-color: rgba(84, 170, 1, 0.3);
      padding: 4rem; }
      @media only screen and (max-width: 37.5em) {
        .scholarship__details-img-box-2 {
          padding: 2rem; } }
    @media only screen and (max-width: 37.5em) {
      .scholarship__details-img-box {
        padding: 2rem; } }
  .scholarship__details-img-circle i {
    font-size: 20rem; }
    @media only screen and (max-width: 37.5em) {
      .scholarship__details-img-circle i {
        height: 25rem;
        width: 25rem; } }

.scholarship__actions {
  display: flex;
  justify-content: space-between;
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 4rem; }
  @media only screen and (max-width: 37.5em) {
    .scholarship__actions {
      flex-direction: column; } }

.scholarship__enquiry {
  flex: 0 1 40%; }
  .scholarship__enquiry h4 {
    font-size: 1.6rem; }
  @media only screen and (max-width: 37.5em) {
    .scholarship__enquiry {
      margin-bottom: 2rem; } }

.scholarship__faq {
  flex: 0 1 50%; }

.scholarship__contact-info {
  font-size: 1.8rem; }
  .scholarship__contact-info a {
    color: #54aa01; }

.our-team {
  margin-top: 12rem; }
  .our-team__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url(../public/img/didomi-19.jpg);
    background-size: cover;
    height: 120vh; }
    @media only screen and (max-width: 75em) {
      .our-team__header {
        height: 90vh; } }
    @media only screen and (max-width: 56.25em) {
      .our-team__header {
        height: 70vh; } }
    @media only screen and (max-width: 37.5em) {
      .our-team__header {
        height: 40vh; } }
  .our-team__header-text {
    font-size: 4rem;
    font-weight: 800;
    color: #FFF;
    margin-top: 3rem;
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out; }
  .our-team__row {
    width: 90%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 5rem auto; }
    @media only screen and (max-width: 56.25em) {
      .our-team__row {
        margin-bottom: 2rem;
        margin-top: 2rem; } }
    @media only screen and (max-width: 37.5em) {
      .our-team__row {
        margin-top: 3.5rem; } }
  .our-team__images {
    display: flex;
    justify-content: space-evenly; }
    @media only screen and (max-width: 37.5em) {
      .our-team__images {
        flex-direction: column;
        align-items: center; } }

.page-contact-us {
  background-color: rgba(84, 170, 1, 0.1);
  margin-bottom: -5rem;
  margin-top: 12rem; }
  .page-contact-us__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(../public/img/didomi-9.jpg);
    background-size: cover;
    height: 100vh; }
    @media only screen and (max-width: 56.25em) {
      .page-contact-us__header {
        height: 70vh; } }
    @media only screen and (max-width: 37.5em) {
      .page-contact-us__header {
        height: 40vh; } }
  .page-contact-us__header-text {
    font-size: 4rem;
    font-weight: 800;
    color: #FFF;
    margin-top: 3rem;
    animation-name: moveInBottom;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-timing-function: ease-out; }
  .page-contact-us__container {
    width: 90%;
    margin: 5rem auto; }
  .page-contact-us__row {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 37.5em) {
      .page-contact-us__row {
        flex-direction: column; } }
  .page-contact-us__form-info {
    flex: 0 1 60%; }
    .page-contact-us__form-info h3 {
      font-size: 2.5rem;
      font-weight: 900; }
  .page-contact-us__form {
    font-size: 1.8rem; }
  .page-contact-us__form-row {
    margin-bottom: 1rem; }
    .page-contact-us__form-row label {
      display: block;
      font-weight: 600;
      color: #2E1114; }
      .page-contact-us__form-row label span {
        color: red; }
    .page-contact-us__form-row input {
      border: none;
      width: 40rem;
      height: 4rem;
      font-size: 1.7rem;
      padding-left: 8px;
      outline: none; }
      @media only screen and (max-width: 37.5em) {
        .page-contact-us__form-row input {
          width: 30rem;
          height: 3.5rem; } }
      .page-contact-us__form-row input:focus {
        border: 2px solid #54aa01; }
    .page-contact-us__form-row textarea {
      border: none;
      width: 40rem;
      padding-left: .8rem;
      padding-top: .8rem;
      font-size: 1.7rem;
      outline: none;
      resize: none; }
      @media only screen and (max-width: 37.5em) {
        .page-contact-us__form-row textarea {
          width: 30rem; } }
      .page-contact-us__form-row textarea:focus {
        border: 2px solid #54aa01; }
    .page-contact-us__form-row button {
      text-align: center;
      width: 8rem;
      height: 3.5rem;
      border-radius: 5px;
      border-color: #FFF;
      cursor: pointer;
      background-color: #54aa01;
      color: #FFF; }
      .page-contact-us__form-row button:hover, .page-contact-us__form-row button:active {
        background-color: #FFF;
        color: #54aa01;
        font-weight: 700;
        border-color: #54aa01; }
    .page-contact-us__form-row p {
      font-size: 1.3rem;
      color: red;
      margin-bottom: .5rem; }
  .page-contact-us__location-info {
    flex: 0 1 30%; }
    @media only screen and (max-width: 37.5em) {
      .page-contact-us__location-info {
        margin-top: 3rem; } }
    .page-contact-us__location-info h3 {
      font-size: 2.5rem;
      font-weight: 900; }
    .page-contact-us__location-info h4 {
      font-size: 2rem;
      font-weight: 600; }

.apply-page__row {
  margin-top: 18rem;
  margin-bottom: 15rem;
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 37.5em) {
    .apply-page__row {
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 15rem;
      margin-bottom: 8rem; } }

.apply-page__info {
  flex: 0 1 35%;
  margin-right: 2rem; }
  @media only screen and (max-width: 75em) {
    .apply-page__info {
      flex: 0 1 40%; } }
  @media only screen and (max-width: 37.5em) {
    .apply-page__info {
      width: 95%;
      margin: auto;
      align-self: center; } }

.apply-page__form-container {
  flex: 0 1 35%; }
  @media only screen and (max-width: 75em) {
    .apply-page__form-container {
      flex: 0 1 50%; } }
  @media only screen and (max-width: 37.5em) {
    .apply-page__form-container {
      width: 95%;
      margin-top: 5rem;
      display: flex;
      justify-content: center; } }

.login-page__row {
  margin-top: 18rem;
  margin-bottom: 15rem;
  display: flex;
  justify-content: center; }
  @media only screen and (max-width: 37.5em) {
    .login-page__row {
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 15rem;
      margin-bottom: 8rem; } }

.login-page__info {
  flex: 0 1 35%;
  margin-right: 2rem; }
  @media only screen and (max-width: 37.5em) {
    .login-page__info {
      width: 95%;
      margin: auto;
      align-self: center; } }

.login-page__form-container {
  flex: 0 1 35%; }
  @media only screen and (max-width: 37.5em) {
    .login-page__form-container {
      width: 95%;
      margin-top: 5rem;
      display: flex;
      justify-content: center; } }

.reset-password {
  margin-top: 18rem;
  margin-bottom: 30rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .reset-password__form-container {
    width: 30%; }
    @media only screen and (max-width: 56.25em) {
      .reset-password__form-container {
        width: 50%; } }
    @media only screen and (max-width: 37.5em) {
      .reset-password__form-container {
        width: 70%; } }
    @media only screen and (max-width: 22.1em) {
      .reset-password__form-container {
        width: 90%; } }

.dashboard {
  background-color: rgba(84, 170, 1, 0.1);
  color: #000;
  margin-top: 12rem;
  padding-top: 5rem;
  font-size: 1.6rem; }
  .dashboard__header {
    margin-left: 3%; }
  .dashboard__container {
    width: 94%;
    margin: auto;
    display: flex;
    justify-content: space-between; }
  .dashboard__container-1 {
    flex: 0 1 18%; }
    @media only screen and (max-width: 56.25em) {
      .dashboard__container-1 {
        display: none; } }
  .dashboard__container-2 {
    flex: 0 1 80%; }
    @media only screen and (max-width: 56.25em) {
      .dashboard__container-2 {
        flex: 0 1 100%; } }

.dashboard-nav {
  margin-right: 2rem; }
  .dashboard-nav__item {
    margin-bottom: 1rem;
    border-radius: 4px;
    padding: 1rem; }
    .dashboard-nav__item a:link, .dashboard-nav__item a:visited, .dashboard-nav__item :active {
      text-decoration: none;
      color: #000; }
  .dashboard-nav__item:hover {
    background-color: rgba(84, 170, 1, 0.3); }
    .dashboard-nav__item:hover a {
      color: #800080; }

.dashboard-main {
  display: flex;
  justify-content: space-between;
  background-color: #FAF9F6;
  padding: 2rem;
  padding-top: 4rem;
  border-radius: 4px;
  box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 37.5em) {
    .dashboard-main {
      flex-direction: column-reverse; } }
  .dashboard-main__header {
    margin-top: 1rem;
    font-size: 2.2rem; }
  .dashboard-main__1 {
    flex: 0 1 70%; }
  .dashboard-main__2 {
    flex: 0 1 20%; }
    @media only screen and (max-width: 37.5em) {
      .dashboard-main__2 {
        align-self: center; } }

.user__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem; }
  @media only screen and (max-width: 37.5em) {
    .user__info {
      margin-bottom: 1rem;
      flex-direction: column; } }

.user__info-1 {
  flex: 0 1 30%;
  padding: 1rem; }
  @media only screen and (max-width: 37.5em) {
    .user__info-1 {
      flex: 0 1 40%;
      padding: .5rem; } }

@media only screen and (max-width: 37.5em) {
  .user__info-1-dis-none-phone {
    display: none; } }

.user__info-2 {
  flex: 0 1 60%;
  background-color: rgba(84, 170, 1, 0.5);
  color: #FFF;
  font-weight: 600;
  padding: 1rem;
  border-radius: 4px; }
  @media only screen and (max-width: 37.5em) {
    .user__info-2 {
      padding: .5rem; } }

.user__avatar i {
  font-size: 8rem; }
  @media only screen and (max-width: 37.5em) {
    .user__avatar i {
      font-size: 4rem; } }

.user-table {
  border-spacing: 1rem; }
  .user-table th {
    background-color: rgba(84, 170, 1, 0.7);
    color: #FFF;
    font-weight: 600; }
  .user-table th, .user-table td {
    padding: .3rem .6rem;
    text-align: center; }
  .user-table td {
    background-color: rgba(84, 170, 1, 0.3); }
  .user-table td i, .user-table td span {
    color: #800080;
    cursor: pointer; }
  .user-table td a {
    text-decoration: none;
    color: #800080; }

.dashboard-pagin {
  margin-top: 1rem; }
  .dashboard-pagin__button {
    padding: .8rem;
    color: #FFF;
    background-color: #54aa01;
    font-weight: bold;
    cursor: pointer;
    margin: 1rem;
    border: none;
    border-radius: 2px; }
    .dashboard-pagin__button:active {
      background-color: rgba(84, 170, 1, 0.4); }
